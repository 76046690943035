import {LocalizationType} from 'shared/localization/ru';

export const en: LocalizationType = {
	'common.prtv': 'PRTV',
	'common.profile': 'Profile',
	'common.exit': 'Log Out',
	'common.login': 'Log In',
	'common.registration': 'Registration',
	'common.slideShow': 'slide-show',
	'common.header': 'Header',
	'common.apply': 'Apply',
	'common.save': 'Save',
	'common.cancel': 'Cancel',
	'common.edit': 'Edit',
	'common.delete': 'Delete',
	'common.text': 'Text',
	'common.search': 'Search',
	'common.error': 'Error',
	'common.add': 'Add',
	'common.title': 'Title',
	'common.yes': 'Yes',
	'common.no': 'No',
	'common.back': 'Back',
	'common.name': 'Name',
	'common.surname': 'Surname',
	'common.company': 'Company',
	'common.telephone': 'Telephone',
	'common.actions': 'Actions',
	'common.password': 'Password',
	'common.loading': 'Loading...',
	'common.open': 'Open',
	'common.video': 'Video',

	'Asia/Anadyr': 'Anadyr',
	'Asia/Barnaul': 'Barnaul',
	'Asia/Chita': 'Chita',
	'Asia/Irkutsk': 'Irkutsk',
	'Asia/Kamchatka': 'Kamchatka',
	'Asia/Khandyga': 'Khandyga',
	'Asia/Krasnoyarsk': 'Krasnoyarsk',
	'Asia/Magadan': 'Magadan',
	'Asia/Novokuznetsk': 'Novokuznetsk',
	'Asia/Novosibirsk': 'Novosibirsk',
	'Asia/Omsk': 'Omsk',
	'Asia/Sakhalin': 'Sakhalin',
	'Asia/Srednekolymsk': 'Srednekolymsk',
	'Asia/Tomsk': 'Tomsk',
	'Asia/Ust-Nera': 'Ust-Nera',
	'Asia/Vladivostok': 'Vladivostok',
	'Asia/Yakutsk': 'Yakutsk',
	'Asia/Yekaterinburg': 'Yekaterinburg',
	'Europe/Astrakhan': 'Astrakhan',
	'Europe/Kaliningrad': 'Kaliningrad',
	'Europe/Kirov': 'Kirov',
	'Europe/Moscow': 'Moscow',
	'Europe/Samara': 'Samara',
	'Europe/Saratov': 'Saratov',
	'Europe/Simferopol': 'Simferopol',
	'Europe/Ulyanovsk': 'Ulyanovsk',
	'Europe/Volgograd': 'Volgograd',

	'homePage.advertisingPlacement':
		'Place advertisements on screens in residential building and office building lobbies.',
	'homePage.signUpFree': 'Sign up for free',
	'homePage.opportunities': 'Opportunities',
	'homePage.description':
		'Create presentations for televisions with a wide range of possibilities in just a few clicks. Vast selection of data sources available, including weather, currency exchange rates, VK, OK, Facebook, and more.',
	'homePage.personalization': 'Personalization',
	'homePage.personalization.description':
		'Complete customization of the presentation: set background color, font color and size, text indentation.',
	'homePage.easeOfLearning': 'Ease of learning',
	'homePage.easeOfLearning.description':
		'The presentation creation interface is intuitively understandable, and it will be effortless for you to create presentations of any complexity!',
	'homePage.freeSupport': 'Free support',
	'homePage.freeSupport.description':
		'Feel free to reach out to us with any questions related to our application.',

	'contacts.support-email': 'info@4prtv.ru',
	'contacts.forumUrl': 'https://s.prtv.su/forums/?option=oauthredirect&app_name=prtv',
	'contacts.supportUrl':
		'https://chat.integracio.ru/689d65cc29dc1df3df6dcea76c91ae92/s.prtv.su/ru',
	'errors.server-error': 'An error occurred on the server. Please try again later.',
	'pages.contactsTitle': 'Contacts',

	'widgets.errors.select-video': 'Select a video to show in the player.',
	'widgets.errors.inValidLink': 'Invalid link.',

	'admin.faq.error': 'The field cannot be empty',
	'admin.faq.modalTitle': 'Change section',
	'admin.faq.enterTitle': 'Please enter the title',
	'admin.faq.enterContent': 'Please enter the content',
	'admin.faq.content': 'Content',

	'admin.news.errorsTitle': '* You need to provide a title.',
	'admin.news.errorsArticle': '* The article should not be empty.',
	'admin.news.errorsCompare': 'Changed nothing!',
	'admin.news.setTitle': 'Set a title...',
	'admin.news.writeText': 'Write the text',
	'admin.news.noNews': 'There are no news to display.',
	'admin.news.article': 'Article',
	'admin.news.updated': 'Updated',
	'admin.news.editNews': 'Edit the news!',
	'admin.news.newNews': 'New news!',
	'admin.news.addNews': 'Add news',

	'admin.screens.noScreens': 'No screens.',
	'admin.screens.user': 'User',
	'admin.screens.address': 'Address',
	'admin.screens.photo': 'Photo',
	'admin.screens.typeOfRoom': 'Type of room',
	'admin.screens.dateOfCreation': 'Date of creation',

	'admin.services.deleteQuestion': 'Delete question?',
	'admin.services.addElement': 'Add element',
	'admin.services.noData': 'No data to display',
	'admin.services.listOfServices': 'Loading a list of services...',
	'admin.services.deleteOffer': 'Delete offer?',
	'admin.services.addPrice': 'Enter the price',
	'admin.services.package': 'Select package',
	'admin.services.packageChange': 'Change package',
	'admin.services.addPackage': 'Add package',
	'admin.services.packageSelection': 'Package selection',
	'admin.services.toAddPackage': 'Add package',
	'admin.services.packages': 'Packages',
	'admin.services.packageCoast': 'Package cost for the current offer',
	'admin.services.incorrectValue': 'Incorrect value',
	'admin.services.addOffer': 'Add offer',
	'admin.services.toAddOffer': 'Add offer',
	'admin.services.timeLimit': 'Title of time limit',
	'admin.services.numberOfMonth': 'Number of months',
	'admin.services.priceForMonth': 'Discount percentage',
	'admin.services.discount': 'Discount percentage',
	'admin.services.defaultSelected': 'Selected by default?',
	'admin.services.serviceTitle': 'Service title',
	'admin.services.packageNotFound': 'No packages found. Add them first',
	'admin.services.noPackages': 'No packages',
	'admin.services.paidServices': 'Paid services',
	'admin.services.price': 'Price',
	'admin.services.noOffersSelected': 'There are no offers in the selected service',
	'admin.services.noInputParameters': 'Input parameters not passed',
	'admin.services.serviceChange': 'Change of service',
	'admin.services.addService': 'Adding a service',
	'admin.services.serviceDescription': 'Description of service',
	'admin.services.description': 'Description',

	'admin.surveys.yourOption': 'Your option',
	'admin.surveys.valueRequired': '* Value required',
	'admin.surveys.enterValue': 'Please enter a value',
	'admin.surveys.needToAsk': '* Need to ask a question.',
	'admin.surveys.completeAtList': '* Complete at least 2 answers per question.',
	'admin.surveys.fillInAll': '* Fill in ALL answer options',
	'admin.surveys.newPoll': 'New survey',
	'admin.surveys.toAddPoll': 'Add survey',
	'admin.surveys.askQuestion': 'Ask a question...',
	'admin.surveys.yourQuestion': 'Your question',
	'admin.surveys.answerOption': 'Answer option',
	'admin.surveys.yourAnswer': 'Your answer',
	'admin.surveys.addAnswer': 'Add answer',
	'admin.surveys.allowToAnswer': 'Allow yourself to answer',
	'admin.surveys.allowOneAnswer': 'Allow only one answer!',
	'admin.surveys.integration': 'Integration with SendPulse',
	'admin.surveys.setVariable': 'Set the SendPulse variable',
	'admin.surveys.essenceSurvey': 'Name of the SendPulse variable (the essence of the survey)',
	'admin.surveys.activateSurvey': 'Activate survey!',
	'admin.surveys.noSurveysToDisplay': 'There are no surveys to display.',
	'admin.surveys.question': 'Question',
	'admin.surveys.customField': 'Custom field',
	'admin.surveys.completedSurvey': 'Completed the survey',
	'admin.surveys.variableSendPulse': 'SendPulse variable',
	'admin.surveys.active': 'Active',
	'admin.surveys.updated': 'Updated',
	'admin.surveys.statistics': 'Statistics',
	'admin.surveys.toDeactivate': 'Deactivate',
	'admin.surveys.toActivate': 'Activate',
	'admin.surveys.thisSurvey': 'this survey for all users',
	'admin.surveys.deactivate': 'Deactivate',
	'admin.surveys.activate': 'Activate',
	'admin.surveys.aSurvey': 'a survey for all users',
	'admin.surveys.editYourSurvey': 'Edit your survey!',
	'admin.surveys.noInformation': 'No information to display.',
	'admin.surveys.surveyResults': 'Survey results',
	'admin.surveys.usersCompletedSurvey': 'Users who completed the survey',

	'admin.users.usersRights': 'User rights',
	'admin.users.admin': 'Administrator',
	'admin.users.notAdmin': 'Not an administrator',
	'admin.users.removeRights': 'To remove rights',
	'admin.users.giveRights': 'To give rights',
	'admin.users.ofAdmin': 'of administrator',
	'admin.users.redactor': 'Template editor',
	'admin.users.notRedactor': 'Not a template editor',
	'admin.users.newPasswordSet': 'New password has been set!',
	'admin.users.toChangePass': 'Change Password',
	'admin.users.passwordMismatch': 'Password mismatch',
	'admin.users.atListPass': 'At least 6 characters',
	'admin.users.noMorePass': 'No more than 20 characters',
	'admin.users.newPass': 'New password',
	'admin.users.repeatPass': 'Repeat password',
	'admin.users.changePass': 'Change password',
	'admin.users.profileSaved': 'Profile saved',
	'admin.users.profileEditing': 'Profile editing',
	'admin.users.userDeleted': 'User deleted',
	'admin.users.temporarilyNotWorking': 'Temporarily not working',
	'admin.users.noUsersToDisplay': 'There are no users to display.',
	'admin.users.registrationDate': 'Registration date',
	'admin.users.fullName': 'Full name',
	'admin.users.loginAcc': 'Login to your personal account',
	'admin.users.approve': 'Approve',
	'admin.users.block': 'Block',

	'auth.emailNotFound': 'E-mail not found',
	'auth.errRecoveryPass': 'Password recovery error',
	'auth.accessRecovery': 'Access recovery',
	'auth.accessRecoveryDescription':
		'To restore access, enter the E-mail you used during registration. We will send password recovery instructions to it.',
	'auth.send': 'Send',
	'auth.emailSent': 'An email with instructions has been sent to your email.',
	'auth.errEmailPass': 'Invalid E-mail or password',
	'auth.accessBlocked': 'Access blocked',
	'auth.forgotPass': 'Forgot password',
	'auth.termsOfUse': 'Terms of use',
	'auth.privacyPolicy': 'Privacy policy',
	'auth.pricesForServices': 'Prices for services',
	'auth.regDescription': 'The user already exists, try "Log In" or "Forgot password"',
	'auth.register': 'Register',
	'auth.newPass': 'Entering a new password',

	'components.SelectSS': 'Select slide-show',
	'components.firsChooseSS': 'First, choose your slideshow!',
	'components.showDate': 'Show date',
	'components.showStartDate': 'Show start date',
	'components.showEndDate': 'Show end date',
	'components.allDay': 'All day',
	'components.showWarning': 'The end date of the show must be later than the start date',

	'components.repeat': 'Repeat',
	'components.notRepeat': 'Do not repeat',
	'components.daily': 'Daily',
	'components.weekly': 'Weekly',
	'components.monthly': 'Monthly',
	'components.yearly': 'Annually',
	'components.daysOfWeek': 'Days of the week',
	'components.howManyTimes': 'How many times',
	'components.untilDate': 'Until date',
	'components.impressionWarning': 'Impression overlays detected!',

	'messages.today': 'Today',
	'messages.repeat': 'Repeat',
	'messages.allTasks': 'All tasks',
	'messages.current': 'Current',
	'messages.currentAndFollowing': 'Current and following',
	'messages.menuDeletingTitle': 'Edit a recurring task',
	'methods.errorMessage.destination': 'Incorrect destination',
	'methods.errorMessage.departure': 'Incorrect departure location',

	'common.userHeader.slideShow': `Slide-show`,
	'common.userHeader.layouts': 'Layouts',
	'common.userHeader.collections': 'Collections',
	'common.userHeader.streams': 'Streams',
	'common.userHeader.videoHosting': 'Video Hosting',
	'common.userHeader.informers': 'Informers',
	'common.userHeader.instructions': 'Instructions',
	'common.userHeader.technicalSupport': 'Technical Support',
	'common.userHeader.forum': 'Forum',
	'common.userHeader.contacts': 'Contacts',
	'common.userHeader.myTariff': 'My Tariff',

	'common.checkoutHeader.tariffPayment': 'Payment of the tariff',
	'common.checkoutSlideshowHeader.slideShowPurchase': 'Purchase of a slide-show',

	'common.adminHeader.controlPanel': 'Control panel',
	'common.adminHeader.users': 'Users',
	'common.adminHeader.apps': 'Screens',
	'common.adminHeader.services': 'Services',
	'common.adminHeader.surveys': 'Surveys',
	'common.adminHeader.news': 'News',

	'common.ui.fileLarger': 'File size is larger than expected',
	'common.ui.unsupportedFormat': 'Unsupported format',
	'common.ui.selectVideo': 'Select video',
	'common.ui.uploadVideo':
		'You can upload videos in the following formats: MP4, AVI and no more than 300MB in size',
	'common.ui.uploadVideoError': 'File size exceeds maximum allowed',
	'common.ui.uploadImageError': 'An error occurred while loading the image, please try again',
	'common.ui.choose': 'Choose',
	'common.ui.change': 'Change',
	'common.ui.onComputer': 'On the computer',
	'common.ui.fromCollection': 'From the collection',
	'common.ui.voted': 'Voted',
	'common.ui.requiredField': 'Required field',

	'editor.element.fromWhere': 'From were',
	'editor.element.where': 'Where',
	'editor.element.typeOfTransport': 'Type of transport',
	'editor.element.airplane': 'Airplane',
	'editor.element.train': 'Train',
	'editor.element.electricTrain': 'Electric train',
	'editor.element.bus': 'Bus',
	'editor.element.output': 'Output',
	'editor.element.telegramId': 'Telegram Channel ID',
	'editor.element.hideText': 'Hide text',
	'editor.element.hideText.tooltip': 'Allows you to display only photos from publications',
	'editor.element.hidePictures': 'Hide pictures',
	'editor.element.hidePictures.tooltip': 'Allows you to display only text from publications',
	'editor.element.numberOfMessages': 'Number of messages',
	'editor.element.numberOfMessages.tooltip':
		'Sets the number of recent messages displayed in the widget',

	'editor.element.textAlignment': 'Text alignment',
	'editor.element.textLineHeight': 'Text line height',
	'editor.element.letterSpacing': 'Letter spacing',
	'editor.element.indentation': 'Indentation',
	'editor.element.lineText': 'Line text',
	'editor.element.scrollSpeed': 'Scroll speed',
	'editor.element.scrollDirection': 'Scroll direction',
	'editor.element.service': 'Service',
	'editor.element.scale': 'Scale',
	'editor.element.toDisplay': 'To display',
	'editor.element.userLists': 'User lists',
	'editor.element.selectList': 'Select list',
	'editor.element.userCollections': 'User collections',
	'editor.element.selectCollection': 'Select a collection',
	'editor.element.outputtingTweets': 'Outputting tweets',
	'editor.element.outputtingTweets.tooltip': `Supported formats:<br />
    Tag - for example mtv (without #)<br />
    Username - ivan133 (without @)<br />
    List - can be viewed at https://twitter.com/ivan133/lists<br />
    or create on the service https://tweetdeck.twitter.com/<br />
    Collection - can be created at https://tweetdeck.twitter.com/ (collection)<br />`,
	'editor.element.errorEnterLink': 'Enter link',
	'editor.element.errorInvalidVideoLink': 'Invalid video link format',
	'editor.element.errorUploadVimeo':
		'Uploading videos via kinescope is a paid functionality. Check out the rates on the page',
	'editor.element.myTariff': 'My tariff',
	'editor.element.videoHosting': 'Video hosting',
	'editor.element.linkVideoPlaylist': 'Link to video, playlist or channel',
	'editor.element.linkVideoPlaylist.tooltip': `Supported formats:<br />
Channel link
https://www.youtube.com/channel/UCSyg9cb3Iq-NtlbxqNB9wGw<br />
Channel ID https://www.youtube.com/watch?v=Sw3B6MQMjSU<br />
Short link https://youtu.be/iYfVcD-GPT8<br />
Regular link https://www.youtube.com/watch?v=YqeW9_5kURI<br />
Playlist
https://www.youtube.com/playlist?list=PLyFnbgoS1oYc_D7rRQWKYzAaTt7wbkoZL<br />
Embedded link
https://www.youtube.com/embed/videoseries?list=PLh_0hw-7CaS9Q0QZQ0IFsAawORIxeRzli<br />
Channel ID https://www.youtube.com/@FFChannel_Official`,
	'editor.element.linkToVideo': 'Link to video',
	'editor.element.linkToVideo.tooltip': `<p>Supported formats:</p><br />
<p>https://vk.com/video-00000000_000000000</p><br />
<p> https://vk.com/group_name?z=video-00000000_000000000%2Fvideos-00000000%2Fpl_-00000000_-2
</p><br />
<p>https://vk.com/clips-00000000?z=clip-00000000_000000000</p>`,
	'editor.element.numberOfVideos': 'Number of videos',
	'editor.element.display': 'Display',
	'editor.element.weatherItemNoSupported':
		'This item is no longer supported. Remove it and use the new weather widget',
	'editor.element.cityOrTown': 'City or town',
	'editor.element.hideTitle': 'Hide title',
	'editor.element.iconSet': 'Icon set',
	'editor.element.iconSet.tooltip': 'Allows you to select one of several icon sets',
	'editor.element.additionalParameters': 'Show additional parameters',
	'editor.element.newLine': 'A new line',
	'editor.element.namesPairs': 'Names of pairs.',
	'editor.element.iconPairs': 'Icons for pairs.',
	'editor.element.widgetOption': 'Widget option',
	'editor.element.widgetOption.tooltip': 'Allows you to choose from several widget designs',
	'editor.element.day': 'Day',
	'editor.element.day.tooltip': 'Allows you to select for which day to display the forecast',
	//
	'editor.element.tomorrow': 'Tomorrow',
	'editor.element.afterTomorrow': 'Day after tomorrow',
	'editor.element.4thDay': '4th day',
	'editor.element.5thDay': '5th day',
	'editor.element.tags': 'Tags',
	'editor.element.time': 'Time',
	'editor.element.temperature': 'Temperature',
	'editor.element.header.tooltip': 'Font header display settings',
	'editor.element.time.tooltip': 'Time display settings',
	'editor.element.temp.tooltip': 'Temperature display settings',
	'editor.element.text.tooltip': 'Text display settings',
	'editor.element.element.tooltip': 'Element font display settings',
	'editor.element.meetingNumber': 'Meeting number',
	'editor.element.displayName': 'Display name',
	'editor.element.mail': 'Mail',
	'editor.element.logoCorner': 'Logo in the corner',
	'editor.element.logoLocation': 'Logo location',
	'editor.element.topLeftCorner': 'Top-left corner',
	'editor.element.topRightCorner': 'Top-right corner',
	'editor.element.bottomLeftCorner': 'Bottom-left corner',
	'editor.element.bottomRightCorner': 'Bottom-right corner',
	'editor.element.saveProportions': 'To save proportions',
	'editor.element.saveProportions.tooltip':
		'If this option is enabled, when resizing an element, the proportions will be preserved',

	'editor.modals.setLinkWhenClick': 'Set a link when clicking on an element',
	'editor.modals.link': 'link',
	'editor.modals.removeClickability': 'Remove clickability',
	'editor.modals.addFromSaved': 'Adding from saved',
	'editor.modals.chooseLayout': 'Choose a layout',
	'editor.modals.systemic': 'Systemic',
	'editor.modals.createSlide': 'Create slide',
	'editor.modals.setElementIdForAdCompany':
		'Set the element identifier for the advertising company',
	'editor.modals.elementIdForAdCompany': 'Element identifier for advertising company',
	'editor.modals.setElementAnimation': 'Setting element animation',
	'editor.modals.animationStyle': 'Animation style',
	'editor.modals.transitionDelay': 'Delay before animation starts (ms)',
	'editor.modals.transitionDuration': 'Animation duration (ms)',
	'editor.modals.transitionCount': 'Number of animation repetitions (0 - infinite)',
	'editor.modals.setElementStyle': `Setting an element's style`,
	'editor.modals.update': 'Update',
	'editor.modals.vectorElCollection': 'Collection of vector elements',
	'editor.modals.slideDeletionConfirm': 'Confirm deletion of slide',
	'editor.modals.slideSaving': 'Saving slide',
	'editor.modals.slideDeleting': 'Deleting slide',
	'editor.modals.slideTitle': 'Slide title',
	'editor.modals.slideSettings': 'Slide settings',
	'editor.modals.filter': 'Filter',
};
