import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import {Dialog, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
	videoContainer: {
		position: 'relative',
		paddingTop: '56.25%', // 16:9 Aspect Ratio
		width: '100%',
	},
	iframe: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		border: 0,
	},
});

interface IVimeoVideoDialogProps {
	video: string;
	onClose: () => void;
	isOpened: boolean;
	title: string;
}

const VimeoVideoDialog: React.FC<IVimeoVideoDialogProps> = ({video, title, onClose, isOpened}) => {
	const classes = useStyles();

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Dialog
			title={title}
			open={isOpened}
			onClose={handleClose}
			maxWidth={'md'}
			fullWidth={true}
		>
			{/*<Vimeo video={video} controls autoplay responsive />*/}
			<div className={classes.videoContainer}>
				<iframe
					className={classes.iframe}
					src={video}
					allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;"
					allowFullScreen
				></iframe>
			</div>
			<IconButton
				aria-label="Close"
				onClick={onClose}
				style={{
					position: 'absolute',
					top: '10px',
					right: '10px',
					backgroundColor: '#3F51B5',
				}}
			>
				<CloseIcon style={{color: '#FFFFFF'}} />
			</IconButton>
		</Dialog>
	);
};

export default VimeoVideoDialog;
