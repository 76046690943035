export const sharedConsts = {
	user: {
		slideShowGroupDefault: 'Мои слайд-шоу1',
	},
	slideshow: {
		radioValueDefault: 0.6,
	},
	ya: {
		default: {
			lat: 55.75,
			len: 37.57,
		},
	},
};
