import {Mongo} from 'meteor/mongo';
import {Class} from 'meteor/jagi:astronomy';

/**
 * вход в пейкипер https://prtv.server.paykeeper.ru/login/
 * PaidService Там есть услуги
 * Из них собирается PaidServicePackage (бандл услуг)
 * PaidServiceOffer содержит ссылку на PaidService  как бы товары в корзине формируется до оплаты
 * PaidServicePackagePaidServiceOffer связывает PaidServiceOffer с PaidServicePackage
 *
 * PaidServiceOrder - заказ
 * Создается после оплаты (вебхук от paykeeper)
 * после оплаты создается PaidServicePayment
 * см метод methodNames.paidServices.verifyPayment
 * Далее используется для проверки доступов permissions
 * Пример
 * ```
 * {
 * 	"_id" : "c77MxFHuGuwZuyeBF",
 * 	"userId" : "guK2EjW64tS8MDLKh",
 * 	"paymentId" : "4RvY8Xvpb2BviJGDY",
 * 	"paidService" : {
 * 		"_id" : "fomrc8P9TaQZ6aMwu",
 * 		"title" : "Премиум",
 * 		"key" : "premium",
 * 		"description" : "Премиум тариф открывает новые возможности для представления вашего бизнеса в оффлайне"
 * 	},
 * 	"offer" : {
 * 		"_id" : "CskGkka4mTL7xLM4W",
 * 		"paidServiceId" : "fomrc8P9TaQZ6aMwu",
 * 		"title" : "1 месяц",
 * 		"months" : 1,
 * 		"price" : 0,
 * 		"discount" : 0,
 * 		"defaultSelected" : false
 * 	},
 * 	"packages" : [
 * 		{
 * 			"_id" : "ZGDjjXPmvhZPZfSKr",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "vvYcYphDF9ft5cNFe",
 * 			"price" : 100
 * 		},
 * 		{
 * 			"_id" : "mtPeQK6mPzF833hLK",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "vX6b5dcbHpXLF6tKz",
 * 			"price" : 100
 * 		},
 * 		{
 * 			"_id" : "mtPeQK6mPzF833hLK1",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "hYtY7vYhkQX3PkgSB",
 * 			"price" : 100
 * 		}
 * 	],
 * 	"paid" : true,
 * 	"price" : 300,
 * 	"priceDiscount" : 300,
 * 	"priceDiscountMonthly" : 300,
 * 	"discount" : 0,
 * 	"startedAt" : ISODate("2022-09-16T14:21:54.572+03:00"),
 * 	"expiredAt" : ISODate("2022-10-16T14:21:54.572+03:00"),
 * 	"changePlanAfter" : false,
 * 	"permissions" : [ "VIDEO_HOSTING", "SLIDESHOW_STREAM", "STATISTICS" ]
 * }
 * ```
 *
 * PaidServicePayment - информация по оплате заказа  (связь с paidService, PaidServiceOffer и PaidServicePackagePaidServiceOffer) если paid true то считается оплаченным
 * Пример
 *
 * ```
 * {
 * 	"_id" : "4RvY8Xvpb2BviJGDY",
 * 	"paid" : false,
 * 	"userId" : "guK2EjW64tS8MDLKh",
 * //копируется сюда целиком чтобы в случае изменений исходная информация сохранилась
 * 	"paidService" : {
 * 		"_id" : "fomrc8P9TaQZ6aMwu",
 * 		"title" : "Премиум",
 * 		"key" : "premium",
 * 		"description" : "Премиум тариф открывает новые возможности для представления вашего бизнеса в оффлайне"
 * 	},
 * //копируется сюда целиком чтобы в случае изменений исходная информация сохранилась
 * 	"offer" : {
 * 		"_id" : "CskGkka4mTL7xLM4W",
 * 		"paidServiceId" : "fomrc8P9TaQZ6aMwu",
 * 		"title" : "1 месяц",
 * 		"months" : 1,
 * 		"price" : 0,
 * 		"discount" : 0,
 * 		"defaultSelected" : false
 * 	},
 * //копируется сюда целиком чтобы в случае изменений исходная информация сохранилась (PaidServicePackagePaidServiceOffer)
 * 	"packages" : [
 * 		{
 * 			"_id" : "ZGDjjXPmvhZPZfSKr",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "vvYcYphDF9ft5cNFe",
 * 			"price" : 100
 * 		},
 * 		{
 * 			"_id" : "mtPeQK6mPzF833hLK",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "vX6b5dcbHpXLF6tKz",
 * 			"price" : 100
 * 		},
 * 		{
 * 			"_id" : "mtPeQK6mPzF833hLK1",
 * 			"paidServiceOfferId" : "CskGkka4mTL7xLM4W",
 * 			"paidServicePackageId" : "hYtY7vYhkQX3PkgSB",
 * 			"price" : 100
 * 		}
 * 	],
 * 	"price" : 300,
 * 	"priceDiscount" : 300,
 * 	"priceDiscountMonthly" : 300,
 * 	"discount" : 0,
 * 	"invoiceId" : "20220916142033754",
 * 	"invoiceUrl" : "https://prtv.server.paykeeper.ru/bill/20220916142033754",
 * 	"changePlanAfter" : false
 * }
 * ```
 */

/**
 * Тарифы
 */

export enum PaidServiceKeysEnum {
	DEMO = 'demo',
	PREMIUM = 'premium',
}
export const PaidServicesInitialData = {
	[PaidServiceKeysEnum.DEMO]: {
		key: PaidServiceKeysEnum.DEMO,
		title: 'Демо',
		description: 'Все функции станут доступны на 1 месяц',
	},
	[PaidServiceKeysEnum.PREMIUM]: {
		key: PaidServiceKeysEnum.PREMIUM,
		title: 'Премиум',
		description:
			'Премиум тариф открывает новые возможности для представления вашего бизнеса в оффлайне',
	},
};

export interface IPaidService {
	_id: string;
	title: string;
	key: PaidServiceKeysEnum;
	description: string;
}

export const PaidService = Class.create<IPaidService>({
	name: 'PaidService',
	collection: new Mongo.Collection<IPaidService>('paidService'),
	fields: {
		title: String,
		key: String,
		description: String,
	},
});

/**
 * Офферы
 */
export interface IPaidServiceOffer {
	_id: string;
	paidServiceId: string;
	title: string;
	months: number;
	price: number;
	discount: number;
	defaultSelected?: boolean;
}
export interface IPaidServiceWithOffer extends IPaidService {
	offers: IPaidServiceOffer[];
}
export interface IPaidServiceFull extends IPaidService {
	offers: IPaidServiceOfferWithPackages[];
}
export interface IPaidServiceOfferWithPackages extends IPaidServiceOffer {
	packagesList: IPaidServicePackagePaidServiceOfferFull[];
}
export const PaidServiceOffer = Class.create<IPaidServiceOffer>({
	name: 'PaidServiceOffer',
	collection: new Mongo.Collection<IPaidServiceOffer>('paidServiceOffer'),
	fields: {
		paidServiceId: String,
		title: String,
		months: Number,
		price: Number,
		discount: Number,
		defaultSelected: {
			type: Boolean,
			optional: true,
		},
	},
});

/*
IPaidServiceFull: {
	...PaidService: IPaidService
	offers(IPaidServiceOfferWithPackages): [
		{
			...PaidServiceOffer: IPaidServiceOffer
			packagesList(IPaidServicePackagePaidServiceOfferFull): [
				{
					...PaidServicePackagePaidServiceOffer: IPaidServicePackagePaidServiceOffer
					paidServicePackage: IPaidServicePackage;
				}
			]
		}
	]
}
*/

/**
 * Пакеты
 */

export enum PaidServicePackagesEnum {
	VIDEO_HOSTING = 'VIDEO_HOSTING', // Видеохостинг
	SLIDESHOW_STREAM = 'SLIDESHOW_STREAM', // Запуск слайд-шоу по расписанию
	STATISTICS = 'STATISTICS', // Сбор и просмотр статистики по элементам
}

export const PaidServicePackagesInitialData = {
	[PaidServicePackagesEnum.VIDEO_HOSTING]: {
		key: PaidServicePackagesEnum.VIDEO_HOSTING,
		title: 'Видеохостинг',
	},
	[PaidServicePackagesEnum.SLIDESHOW_STREAM]: {
		key: PaidServicePackagesEnum.SLIDESHOW_STREAM,
		title: 'Запуск слайд-шоу по расписанию',
	},
	[PaidServicePackagesEnum.STATISTICS]: {
		key: PaidServicePackagesEnum.STATISTICS,
		title: 'Сбор и просмотр статистики по элементам',
	},
};

export interface IPaidServicePackage {
	_id: string;
	key: string;
	title: string;
}
export const PaidServicePackage = Class.create<IPaidServicePackage>({
	name: 'PaidServicePackage',
	collection: new Mongo.Collection<IPaidServicePackage>('paidServicePackage'),
	fields: {
		title: String,
		key: String,
	},
});

/**
 * Добавление пакеты к офферу
 */
export interface IPaidServicePackagePaidServiceOffer {
	_id: string;
	paidServiceOfferId: string;
	paidServicePackageId: string;
	price: number;
}
export interface IPaidServicePackagePaidServiceOfferFull
	extends IPaidServicePackagePaidServiceOffer {
	paidServicePackage: IPaidServicePackage;
}
export const PaidServicePackagePaidServiceOffer = Class.create<IPaidServicePackagePaidServiceOffer>(
	{
		name: 'PaidServicePackagePaidServiceOffer',
		collection: new Mongo.Collection<IPaidServicePackagePaidServiceOffer>(
			'paidServicePackagePaidServiceOffer',
		),
		fields: {
			paidServiceOfferId: String,
			paidServicePackageId: String,
			price: Number,
		},
	},
);

/**
 * Заказы
 */
export interface IPaidServiceOrder {
	_id: string;
	userId: string;
	paymentId: string;
	paidService: IPaidService;
	offer: IPaidServiceOffer;
	packages: IPaidServicePackagePaidServiceOffer[];
	paid: boolean;
	price: number;
	priceDiscount: number;
	priceDiscountMonthly: number;
	discount: number;
	startedAt: Date;
	expiredAt: Date;
	changePlanAfter: boolean;
	renew?: boolean;
	permissions?: string[];
}
export const PaidServiceOrder = Class.create<IPaidServiceOrder>({
	name: 'PaidServiceOrder',
	collection: new Mongo.Collection<IPaidServiceOrder>('paidServiceOrder'),
	fields: {
		userId: String,
		// this is paidServicePayment Id
		paymentId: String,
		paidService: PaidService,
		offer: PaidServiceOffer,
		packages: [PaidServicePackagePaidServiceOffer],
		paid: Boolean,
		price: Number,
		priceDiscount: Number,
		priceDiscountMonthly: Number,
		discount: Number,
		startedAt: Date,
		expiredAt: Date,
		changePlanAfter: Boolean,
		renew: {
			type: Boolean,
			optional: true,
		},
		permissions: {
			type: [String],
			optional: true,
		},
	},
});

/**
 * Оплата
 */
export interface IPaidServicePayment {
	_id: string;
	paid: boolean;
	userId: string;
	paidService: IPaidService;
	offer: IPaidServiceOffer;
	packages: IPaidServicePackagePaidServiceOffer[];
	price: number;
	priceDiscount: number;
	priceDiscountMonthly: number;
	discount: number;
	invoiceId: string;
	invoiceUrl: string;
	changePlanAfter: boolean;
	renew?: boolean;
}
export const PaidServicePayment = Class.create<IPaidServicePayment>({
	name: 'paidServicePayment',
	collection: new Mongo.Collection<IPaidServicePayment>('paidServicePayment'),
	fields: {
		paid: {
			type: Boolean,
			default: false,
		},
		userId: String,
		paidService: PaidService,
		offer: PaidServiceOffer,
		packages: [PaidServicePackagePaidServiceOffer],
		price: Number,
		priceDiscount: Number,
		priceDiscountMonthly: Number,
		discount: Number,
		invoiceId: String,
		invoiceUrl: String,
		changePlanAfter: Boolean,
		renew: {
			type: Boolean,
			optional: true,
		},
	},
});

export enum PaidServicePaymentKeysEnum {
	CARD_ONLINE = 'CARD_ONLINE',
	INVOICE = 'INVOICE',
}
