import React, {ChangeEvent, useState} from 'react';
import {withTracker} from 'react-meteor-data-with-tracker';
import {Meteor} from 'meteor/meteor';
import {Box, Container, TextField} from '@material-ui/core';
import UploadVideo from 'client/components/common/ui/video/UploadVideo';
import ButtonCustom from 'client/components/common/ui/ButtonCustom';
import {methodNames} from 'shared/constants/methodNames';
import {useHistory} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import routerUrls from 'client/constants/routerUrls';
import {publishNames} from 'shared/constants/publishNames';
import {PaidServiceOrder, PaidServicePackagesEnum} from 'shared/collections/PaidServices';
import ContentLoader from 'react-content-loader';
import {checkUserPermissions} from 'shared/utils/methods';
const errorMessages = {
	name: 'Введите название',
	video: 'Выберите файл',
};
const kinescopeUploadUrl = 'https://uploader.kinescope.io/v2/video';
const uploadToken = 'f78ec796-0414-4985-842d-ccb9d696d451';
const project_id = '34cab8e4-e5f7-4337-88ea-3f33fa3deacb';

interface VideoAddProps {
	loading: boolean;
	permissions: PaidServicePackagesEnum[];
}

function transliterate(text: string) {
	const map = {
		А: 'A',
		Б: 'B',
		В: 'V',
		Г: 'G',
		Д: 'D',
		Е: 'E',
		Ё: 'Yo',
		Ж: 'Zh',
		З: 'Z',
		И: 'I',
		Й: 'Y',
		К: 'K',
		Л: 'L',
		М: 'M',
		Н: 'N',
		О: 'O',
		П: 'P',
		Р: 'R',
		С: 'S',
		Т: 'T',
		У: 'U',
		Ф: 'F',
		Х: 'Kh',
		Ц: 'Ts',
		Ч: 'Ch',
		Ш: 'Sh',
		Щ: 'Sch',
		Ъ: '',
		Ы: 'Y',
		Ь: '',
		Э: 'E',
		Ю: 'Yu',
		Я: 'Ya',
		а: 'a',
		б: 'b',
		в: 'v',
		г: 'g',
		д: 'd',
		е: 'e',
		ё: 'yo',
		ж: 'zh',
		з: 'z',
		и: 'i',
		й: 'y',
		к: 'k',
		л: 'l',
		м: 'm',
		н: 'n',
		о: 'o',
		п: 'p',
		р: 'r',
		с: 's',
		т: 't',
		у: 'u',
		ф: 'f',
		х: 'kh',
		ц: 'ts',
		ч: 'ch',
		ш: 'sh',
		щ: 'sch',
		ъ: '',
		ы: 'y',
		ь: '',
		э: 'e',
		ю: 'yu',
		я: 'ya',
	};

	return text
		.split('')
		.map((char) => map[char] || char)
		.join('');
}

const VideoAdd: React.FC<VideoAddProps> = ({loading: rootLoading, permissions}) => {
	if (rootLoading) {
		return (
			<Container style={{maxWidth: '1440px'}}>
				<Box mt={1.5}>
					<ContentLoader viewBox="0 0 720 650" height={700} width="100%">
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="36" />
						<rect x="0" y="46" rx="5" ry="5" width="48%" height="80" />
						<rect x="52%" y="46" rx="5" ry="5" width="48%" height="80" />
					</ContentLoader>
				</Box>
			</Container>
		);
	}

	if (
		!(Array.isArray(permissions) ? permissions : []).includes(
			PaidServicePackagesEnum.VIDEO_HOSTING,
		)
	) {
		return <Redirect to={routerUrls.userPlan} />;
	}

	const history = useHistory();
	const [errors, setErrors] = useState({
		name: '',
		video: '',
		message: '',
	});
	const [loading, setLoading] = useState();
	const [loadingPercent, setLoadingPercent] = useState(0);
	const [video, setVideo] = useState();
	const [name, setName] = useState('');

	const handleSelectVideo = (file: File) => {
		setVideo(file);
		setErrors({...errors, video: file ? '' : errorMessages.video});
	};
	const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const isValid = () => {
		setErrors({
			...errors,
			name: !name ? errorMessages.name : '',
			video: !video ? errorMessages.video : '',
		});

		return name && video;
	};

	const saveUserVideo = (id: string, link: string) => {
		// приходит строка
		// const uriSplit = String(id).split('/');
		// Number(uriSplit[uriSplit.length - 1]);
		const videoId = id;
		if (!videoId) {
			alert('Ошибка сохранения видео');
			setLoading(false);
			return;
		}
		Meteor.call(
			methodNames.videoHosting.saveVideo,
			{videoId, name, link, size: video.size},
			(error: Error | Meteor.Error) => {
				setLoading(false);
				if (error) {
					setErrors({
						...errors,
						message: error.message,
					});
				}
				history.push(routerUrls.userVideoHosting);
			},
		);
	};

	const uploadVideoToVimeo = (name: string, file: File) => {
		const request = new XMLHttpRequest();
		request.upload.onprogress = (e) => {
			setLoadingPercent((e.loaded / e.total) * 100);
		};

		request.onload = () => {
			if (request.status >= 200 && request.status < 300) {
				console.log('Файл успешно загружен!', request.responseText);
				/* request.responseText
				"data": {
						"id": "8948b50a-16ff-43c8-b3f5-f391c6139e9b",
						"parent_id": "34cab8e4-e5f7-4337-88ea-3f33fa3deacb",
						"title": "11",
						"description": "11",
						"status": "processing",
						"play_link": "https://kinescope.io/hXeVC1ddH9QkeuKGE8s6VD",
						"embed_link": "https://kinescope.io/embed/hXeVC1ddH9QkeuKGE8s6VD",
						"hls_link": "https://kinescope.io/hXeVC1ddH9QkeuKGE8s6VD/master.m3u8",
						"created_at": "2025-02-21T14:01:59.208192Z"
					}
				*/
				const uploadResponse: any = JSON.parse(request.responseText).data;
				saveUserVideo(uploadResponse.id, uploadResponse.embed_link);
			} else {
				console.error('Ошибка загрузки:', request.status, request.responseText);
			}
		};

		request.onerror = () => {
			console.error('Ошибка сети или сервер не доступен.');
			alert('При загрузке видео произошла ошибка');
		};

		request.onabort = function() {
			console.warn('Загрузка была прервана пользователем.');
		};

		request.onreadystatechange = function() {
			if (request.readyState === 4 && request.status >= 400) {
				console.error('Ошибка при загрузке файла:', request.status, request.responseText);
			}
		};

		const latineName = transliterate(name);

		request.open('POST', kinescopeUploadUrl);
		request.setRequestHeader('Authorization', `Bearer ${uploadToken}`);
		request.setRequestHeader('X-Parent-ID', project_id);
		request.setRequestHeader('X-Video-Title', latineName);
		request.setRequestHeader('X-Video-Description', latineName);
		request.setRequestHeader('X-File-Name', 'video/mp4');

		//request.withCredentials = false;

		request.send(file);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (!isValid()) return;
		const size = video?.size;
		if (!size) return;
		setLoading(true);
		uploadVideoToVimeo(name, video);

		/*Meteor.call(
			methodNames.videoHosting.createVideo,
			{size, name},
			(error: Error | Meteor.Error, response: {uploadLink: string; uri: string}) => {
				if (error) {
					setLoading(false);

					setErrors({
						...errors,
						message: error.message,
					});

					return;
				}
				const err = response.error;
				if (err) {
					if (err.name) {
						setErrors({
							...errors,
							name: err.name,
						});
					}
					if (err.video) {
						setErrors({
							...errors,
							video: err.video,
						});
					}

					return;
				}
				const uploadLink = response?.uploadLink;
				const uri = response?.uri;
				const link = response?.link;

				if (!uploadLink || !uri || !link) {
					setErrors({
						...errors,
						message: 'Ошибка создания видео. Попробуйте позднее',
					});

					return;
				}

				uploadVideoToVimeo(uploadLink, uri, link, video);
			},
		);*/
	};

	return (
		<Container style={{maxWidth: '780px'}}>
			<form onSubmit={handleSubmit}>
				<Box display="flex" mt={1.5} mb={2.5}>
					<Box
						fontSize={20}
						lineHeight={1.4}
						flexGrow={1}
						fontWeight={600}
						textAlign="center"
					>
						Добавление видео
					</Box>
				</Box>
				<Box mb={3}>
					<TextField
						onChange={onNameChange}
						value={name}
						fullWidth
						name="name"
						placeholder="Название"
						error={!!errors.name}
						helperText={errors.name}
						disabled={loading}
						autoComplete="off"
					/>
				</Box>
				<Box mb={3}>
					<UploadVideo
						loading={loading}
						loadingPercent={loadingPercent}
						onSelect={handleSelectVideo}
						error={errors.video}
					/>
				</Box>
				<Box mb={3} textAlign="center">
					<ButtonCustom type="submit" disabled={loading}>
						Загрузить
					</ButtonCustom>
				</Box>
			</form>
		</Container>
	);
};

export default withTracker(() => {
	const psoSubscriber = Meteor.subscribe(publishNames.paidServices.orders).ready();
	const loading = !psoSubscriber;
	const userId = Meteor.userId();
	const permissions = checkUserPermissions(userId);

	return {
		loading,
		permissions,
	};
})(VideoAdd);
