import * as React from 'react';
import {Meteor} from 'meteor/meteor';
import {createStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography/Typography';
import {methodNames} from 'shared/constants/methodNames';
import {IServices} from 'shared/collections/Users';
import {checkAdmin, checkRole} from 'shared/utils/user';
import {LL} from 'shared/localization';
import {PaidServicePackagesEnum} from 'shared/collections/PaidServices';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const styles = createStyles({
	title: {
		paddingTop: '15px',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '16px',
	},
	formControl: {
		minWidth: 200,
		marginRight: 16,
	},
	monthsField: {
		width: 100,
		marginRight: 16,
	},
});

interface IEmail {
	address: string;
	verified: boolean;
}

interface IUser extends Meteor.User {
	_id: string;
	name: string;
	surname: string;
	roles?: [string];
	emails: [IEmail];
	phone: string;
	status?: 'new';
	projectServices: IServices;
	company: string;
	slideShowGroups: {name: string}[];
}

interface IAdminPermissionsProps {
	classes: {
		container: string;
		title: string;
		formControl: string;
		monthsField: string;
	};
	user: IUser;
}

interface IAdminPermissionsState {
	selectedPackage: PaidServicePackagesEnum | string;
	months: number;
	giftSuccess: boolean;
	giftError: string;
}

class AdminPermissions extends React.PureComponent<IAdminPermissionsProps, IAdminPermissionsState> {
	state: IAdminPermissionsState = {
		selectedPackage: '',
		months: 1,
		giftSuccess: false,
		giftError: '',
	};

	handleSetAdmin = (isAdmin: boolean) => () => {
		const {user} = this.props;

		Meteor.call(
			methodNames.user.setAdminRole,
			user._id,
			isAdmin,
			(error: Error | Meteor.Error) => {
				if (error) console.log((error as any).reason || error.message);
			},
		);
	};
	handleSetTemplateEditor = (isAdmin: boolean) => () => {
		const {user} = this.props;

		Meteor.call(
			methodNames.user.setTemplateEditorRole,
			user._id,
			isAdmin,
			(error: Error | Meteor.Error) => {
				if (error) console.log((error as any).reason || error.message);
			},
		);
	};

	handlePackageChange = (event: React.ChangeEvent<{value: unknown}>) => {
		this.setState({selectedPackage: event.target.value as string});
	};

	handleMonthsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const months = parseInt(event.target.value, 10);
		if (months > 0) {
			this.setState({months});
		}
	};

	handleGiftPackage = () => {
		const {user} = this.props;
		const {selectedPackage, months} = this.state;

		if (!selectedPackage) {
			this.setState({giftError: 'Выберите пакет для подарка'});
			return;
		}

		Meteor.call(
			methodNames.paidServices.giftPackage,
			user._id,
			selectedPackage,
			months,
			(error: Error | Meteor.Error | null, result: any) => {
				if (error) {
					this.setState({
						giftError: ((error as any).reason || error.message) as string,
						giftSuccess: false,
					});
				} else if (result.error) {
					this.setState({giftError: result.error.message, giftSuccess: false});
				} else {
					this.setState({
						giftSuccess: true,
						giftError: '',
						selectedPackage: '',
						months: 1,
					});
				}
			},
		);
	};

	render() {
		const {user, classes} = this.props;
		const {selectedPackage, months, giftSuccess, giftError} = this.state;
		const isAdmin = checkAdmin(user);
		const isTemplateEditor = checkRole(user, 'template_editor');
		return (
			<>
				<Typography variant="h5" className={classes.title} gutterBottom>
					{LL('admin.users.usersRights')}
				</Typography>

				<Card className={classes.container}>
					<Typography variant="subtitle1" gutterBottom>
						{isAdmin ? LL('admin.users.admin') : LL('admin.users.notAdmin')}
					</Typography>

					<Button
						variant="outlined"
						color="primary"
						type="button"
						onClick={this.handleSetAdmin(!isAdmin)}
					>
						{isAdmin ? LL('admin.users.removeRights') : LL('admin.users.giveRights')}{' '}
						{LL('admin.users.ofAdmin')}
					</Button>
				</Card>
				<Card className={classes.container}>
					<Typography variant="subtitle1" gutterBottom>
						{isTemplateEditor
							? LL('admin.users.redactor')
							: LL('admin.users.notRedactor')}
					</Typography>

					<Button
						variant="outlined"
						color="primary"
						type="button"
						onClick={this.handleSetTemplateEditor(!isTemplateEditor)}
					>
						{isTemplateEditor
							? LL('admin.users.removeRights')
							: LL('admin.users.giveRights')}
					</Button>
				</Card>

				<Typography variant="h5" className={classes.title} gutterBottom>
					Подарить пакет тарифа
				</Typography>

				<Card className={classes.container}>
					<div>
						<FormControl className={classes.formControl}>
							<InputLabel>Выберите пакет</InputLabel>
							<Select value={selectedPackage} onChange={this.handlePackageChange}>
								<MenuItem value={PaidServicePackagesEnum.VIDEO_HOSTING}>
									Видеохостинг
								</MenuItem>
								<MenuItem value={PaidServicePackagesEnum.SLIDESHOW_STREAM}>
									Запуск слайд-шоу по расписанию
								</MenuItem>
								<MenuItem value={PaidServicePackagesEnum.STATISTICS}>
									Сбор и просмотр статистики
								</MenuItem>
							</Select>
						</FormControl>

						<TextField
							className={classes.monthsField}
							label="Месяцев"
							type="number"
							value={months}
							onChange={this.handleMonthsChange}
							inputProps={{min: 1}}
						/>

						<Button
							variant="contained"
							color="primary"
							onClick={this.handleGiftPackage}
							disabled={!selectedPackage}
						>
							Подарить
						</Button>
					</div>
				</Card>

				{giftSuccess && (
					<Typography variant="body1" style={{color: 'green', margin: '10px 0'}}>
						Пакет успешно подарен пользователю!
					</Typography>
				)}

				{giftError && (
					<Typography variant="body1" style={{color: 'red', margin: '10px 0'}}>
						Ошибка: {giftError}
					</Typography>
				)}
			</>
		);
	}
}

export default withStyles(styles)(AdminPermissions);
