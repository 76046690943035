export default function secondsPretty(seconds: number): string {
	if (!seconds) {
		return '';
	}
	const hoursUnit = Math.floor(seconds / 3600);
	const minutesUnit = Math.floor(seconds / 60);
	const secondsUnit = seconds - minutesUnit * 60;
	const secondsUnitResult = String(secondsUnit.toFixed(2)).padStart(2, '0');

	return `${hoursUnit > 0 ? `${hoursUnit}:` : ``}${minutesUnit}:${secondsUnitResult}`;
}
